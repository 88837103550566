<template>
  <div class="cw-error">
    <div class="cw-error__content">
      <div class="cw-error__message-wrapper">
        <component :is="logo" class="mb-4 cw-error__logo"/>
        <h1
          v-translate="{
            openingTag: '<span>',
            closingTag: '</span>',
          }"
          class="cw-error__headline font-weight-light"
          render-html="true"
        >
          %{ openingTag }OOPS!%{ closingTag } Something went wrong
        </h1>
        <p v-if="text" class="text-h5 font-weight-light mb-0 mt-2" v-text="text"/>
        <v-btn
          id="cw-error__return-to-application"
          class="mt-4"
          to="/"
          dark
          @click="$eventLogger.clickEvent($event)"
        >
          <translate>
            Return to application
          </translate>
        </v-btn>
      </div>
      <div class="cw-error__divider"/>
      <div class="cw-error__code-wrapper">
        <p
          class="cw-error__code font-weight-black mb-0"
          v-text="error.code"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'Error',

  props: {
    clientError: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    apiError: {},
  }),

  computed: {
    ...mapGetters({
      product: 'application/getProduct',
    }),

    ...mapState({
      publicKeys: state => state.apiPublicKeys,
    }),

    error() {
      if (Object.keys(this.apiError).length) return this.apiError;

      if (Object.keys(this.clientError).length) return this.clientError;

      return {
        code: 'oHo',
        message: this.$gettext('Unknown error'),
        title: '',
      };
    },

    logo() {
      // if (!this.product || !this.product.brand) return '';
      // eslint-disable-next-line
      // return () => import('@shared/assets/images/logos/' + this.product.brand.toLowerCase() + '.svg'); // eslint-disable-line
      return () => import('@shared/assets/images/logos/saldo.svg'); // eslint-disable-line
    },

    text() {
      const { message, title } = this.error;

      if (message && message !== '') return message;

      return title;
    },
  },

  watch: {
  },

  methods: {
  },
};
</script>

<style lang="scss">
.cw-error {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;
  min-width: 100%;
  place-items: center;

  &__content {
    align-items: center;
    gap: 16px;
    grid-template-columns: 1fr;
    display: grid;
    justify-content: center;
    padding: 16px;

    @include mq($from: md) {
      gap: 48px;
      grid-template-columns: auto 4px auto;
    }
  }

  &__code {
    font-size: 128px;
    line-height: 1;
    color: palette(Grey, 400);

    @include mq($from: md) {
      font-size: 248px;
    }
  }

  &__code-wrapper {
    grid-row: 1;
    text-align: center;

    @include mq($from: md) {
      text-align: right;
    }
  }

  &__headline {
    line-height: 1;
  }

  &__logo {
    height: 30px;
  }

  &__message-wrapper {
    grid-row: 3;
    text-align: center;

    @include mq($from: md) {
      grid-row: 1;
      text-align: right;
    }

    h1 {
      color: palette(Grey, 500);
    }
  }

  &__divider {
    grid-row: 2;
    height: 100%;
    background-color: palette(Grey, 500);

    @include mq($from: md) {
      grid-row: 1;
    }
  }
}
</style>
